
require('@material/tab/mdc-tab.scss');
require('@material/tab-indicator/mdc-tab-indicator.scss');
require('../../sass/footer/footer.scss');

require('@os/mdc/layout-grid.scss');
require('@os/helper/helper.scss');

require('../../sass/footer_cell/footer_cell.scss');
require('../../sass/footer_contact/footer_contact.scss');
require('../../sass/footer_extra/footer_extra.scss');

require('../../sass/footer-icon-list/footer-icon-list.scss');


define(['jquery', 'js-cookie','@material/tab', '@material/tab-indicator'], function($, jsCookie, mdcTab, mdcTabIndicator) {





	return {
		init: function() {

			var tabs = $('.os-footer .mdc-tab');
		    var tabIndicator = new mdcTabIndicator.MDCTabIndicator(document.querySelector('.os-footer .mdc-tab-indicator'));
			$.each(tabs, function(index, object){
				var tab = new mdcTab.MDCTab($(this)[0]);
				tab.listen('MDCTab:interacted', function(t) {
					$('.footer-contact-tab__control .mdc-tab').removeClass('mdc-tab--active');
					$('.footer-contact-tab__control .mdc-tab .mdc-tab-indicator').removeClass('mdc-tab-indicator--active');
					$('.footer-contact-tab__control .mdc-tab[href="' + t.target.hash + '"]').addClass('mdc-tab--active');
					$('.footer-contact-tab__control .mdc-tab[href="' + t.target.hash + '"] .mdc-tab-indicator').addClass('mdc-tab-indicator--active');
					$('.footer-contact-cell-list').hide();
					$(t.target.hash).show();
				});
				tab.listen('click', function(e){
					e.preventDefault();
					e.stopPropagation();
				})
			});

		}
	};
});
