require('@material/dialog/mdc-dialog.scss');
require('@material/form-field/mdc-form-field.scss');
require('@material/checkbox/mdc-checkbox.scss');
require('@material/radio/mdc-radio.scss');
require('@material/select/mdc-select.scss');

require('@os/mdc/dialog.scss');
require('@os/mdc/text-field.scss');
require('@os/mdc/checkbox.scss');
require('@os/mdc/radio.scss');
require('@os/mdc/floating-label.scss');
require('@os/mdc/select.scss');

require('../../sass/survey-request/survey-request.scss');
require('../../sass/parsleyjs/parsleyjs.scss');

var phoneWidgetTemplate = require('../../view/phone-widget.hbs');

define(['jquery', 'i18next', '@material/dialog', '@material/textfield',
	'@material/checkbox', '@material/radio', '@material/select', '@os/form-data',
	'parsleyjs', '@os/google-recaptcha', 'js-cookie', '@os/phone-widget'
], function ($, i18next, mdcDialog, mdcTextField,
	mdcCheckbox, mdcRadio, mdcSelect, formData,
	parsley, googleRecaptcha, jsCookie, phoneWidget) {

	var templateSurveyRequest = require('../../view/survey-request.hbs');
	var locale = jsCookie.get('locale');

	return {
		init: function () {
			$(document).on('click', '[data-action="survey-request"]', function (event) {
				event.preventDefault();
				event.stopPropagation();

				if ($('#dialog_survey_request').length > 0) {
					$('#dialog_survey_request').remove();
				}
				$.ajax({
					type: "POST",
					url: "/api/survey/request/load",
					data: {
						company_id: ($(this).attr('data-id')) ? $(this).data('id') : null,
						locale: locale
					},
					dataType: "json",
					async: false
				}).done(function (data) {
					data.locale = locale;
					$("body").append(templateSurveyRequest(data));
				});

				var host = window.location.host;
				var hostSplit = host.split('.');
				var tld = hostSplit[hostSplit.length - 1];
				defaultCountry = tld.toUpperCase();

				$('#dialog_survey_request #dialog_survey_request_description').find('.mdc-select').each(function (index, value) {
					mdcSelect.MDCSelect.attachTo($(this)[0]);
				});

				$('#dialog_survey_request #dialog_survey_request_description').find('.mdc-text-field').each(function (index, value) {
					mdcTextField.MDCTextField.attachTo($(this)[0]);
				});

				$('#dialog_survey_request #dialog_survey_request_description').find('.mdc-checkbox').each(function (index, value) {
					checkbox = mdcCheckbox.MDCCheckbox.attachTo($(this)[0]);
				});

				$('#dialog_survey_request #dialog_survey_request_description').find('.mdc-radio').each(function (index, value) {
					radio = mdcRadio.MDCRadio.attachTo($(this)[0]);
				});

				dialog = new mdcDialog.MDCDialog(document.querySelector('#dialog_survey_request'));
				$('.company-phone-widget').append(phoneWidgetTemplate({
					default_country: defaultCountry,
					required: "true",
					label: i18next.t('survey_request:step.form.field.phone.label'),
					name: 'company_phone',
					parsley_group: 'step2',					
					id: 'company_phone',
					lang: locale,
					widget_no: 1
				}));
				$('.phone-widget').append(phoneWidgetTemplate({
					default_country: defaultCountry,
					required: "true",
					label: i18next.t('survey_request:step.form.field.phone.label'),
					name: 'phone',					
					id: 'phone',
					parsley_group: 'step2',
					lang: locale,
					widget_no: 2
				})); 
				phoneWidget.init();
				$('#dialog_survey_request form[name="survey_request_form"]').parsley({
					excluded: 'input[type=button],input[type=submit],input[type=reset],[disabled]'
				});
				googleRecaptcha.render('#dialog_survey_request form[name="survey_request_form"]');
				dialog.show();
			});

			$(document).on('click', '#dialog_survey_request [data-action="change_company"]', function (event) {
				event.preventDefault();
				event.stopPropagation();

				$('#dialog_survey_request').attr('data-company-id', '');
				$('#dialog_survey_request form[name="survey_request_form"] input[name="company_id"]').val('');

				$("#dialog_survey_request .panels .panel").removeClass("os-dialog__panel--active");
				$("#dialog_survey_request .panels .panel:eq(1)").addClass("os-dialog__panel--active");
				$('#dialog_survey_request button[data-action="survey-request-next-step"]').data("step", 2);
			});

			$(document).on('change', 'input[name="siren_check"]', function (event) {
				switch (parseInt($(this).val())) {
					case 1:
						if ($('#dialog_survey_request #siren_bloc').is(':hidden')) {
							$('#dialog_survey_request #siren_bloc').slideDown('slow');
							$('#dialog_survey_request form[name="survey_request_form"] input[name="siren"]').parsley().reset();
						}
						break;
					case 0:
						if ($('#dialog_survey_request #siren_bloc').is(':visible')) {
							$('#dialog_survey_request #siren_bloc').slideUp('slow');
							$('#dialog_survey_request form[name="survey_request_form"] input[name="siren"]').parents('.form-group').removeClass('has-error');
							$('#dialog_survey_request form[name="survey_request_form"] input[name="siren"]').parents('.form-group').find('.help-block').hide();
							$('#dialog_survey_request form[name="survey_request_form"] input[name="siren"]').parsley().destroy();
						}
						break;
				}
			});

			$(document).on('click', 'button[data-action="check-siren"]', function (event) {
				event.preventDefault();
				event.stopPropagation();
			});

			$(document).on('click', 'button[data-action="survey-request-next-step"]', function (event) {
				event.preventDefault();
				event.stopPropagation();

				switch ($(this).data("step")) {
					case 1:
						$("#dialog_survey_request .panels .panel").removeClass("os-dialog__panel--active");
						if ($.trim($('#dialog_survey_request').data('company-id')) != '') {
							$('#dialog_survey_request #company_form_info_bloc').remove();
							$("#dialog_survey_request .panels .panel:eq(2)").addClass("os-dialog__panel--active");
							$(this).html(i18next.t('survey_request:button.send'));
							$(this).data("step", 3);
						} else {
							$("#dialog_survey_request .panels .panel:eq(1)").addClass("os-dialog__panel--active");
							$(this).data("step", 2);
						}
						break;
					case 2:
						if (parseInt($('input[name="siren_check"]:checked').val()) == 1) {
							if ($('#dialog_survey_request form[name="survey_request_form"]').parsley().validate({
									group: 'step1',
									force: true
								})) {
								$.ajax({
									type: 'POST',
									url: '/api/survey/request/company',
									data: {
										siren: $('#dialog_survey_request form[name="survey_request_form"] input[name="siren"]').val()
									},
									dataType: 'json',
									context: this
								}).done(function (data) {
									if (data.success) {
										$('#dialog_survey_request form[name="survey_request_form"] input[name="company_name"]').val(data.response.name);
										$('#dialog_survey_request form[name="survey_request_form"] input[name="company_name"]').next('.mdc-floating-label').addClass('mdc-floating-label--float-above');
										$('#dialog_survey_request form[name="survey_request_form"] input[name="company_address"]').val(data.response.address);
										$('#dialog_survey_request form[name="survey_request_form"] input[name="company_address"]').next('.mdc-floating-label').addClass('mdc-floating-label--float-above');
										$('#dialog_survey_request form[name="survey_request_form"] input[name="company_postal_code"]').val(data.response.postal_code);
										$('#dialog_survey_request form[name="survey_request_form"] input[name="company_postal_code"]').next('.mdc-floating-label').addClass('mdc-floating-label--float-above');
										$('#dialog_survey_request form[name="survey_request_form"] input[name="company_city"]').val(data.response.city);
										$('#dialog_survey_request form[name="survey_request_form"] input[name="company_city"]').next('.mdc-floating-label').addClass('mdc-floating-label--float-above');
										$('#dialog_survey_request form[name="survey_request_form"] input[name="company_phone"]').val(data.response.phone);
										$('#dialog_survey_request form[name="survey_request_form"] input[name="company_phone"]').next('.mdc-floating-label').addClass('mdc-floating-label--float-above');
										$('#dialog_survey_request form[name="survey_request_form"] select[name="company_country"]').val(data.response.country);
										$('#dialog_survey_request form[name="survey_request_form"] select[name="company_country"]').next('.mdc-floating-label').addClass('mdc-floating-label--float-above');
										$("#dialog_survey_request .panels .panel").removeClass("os-dialog__panel--active");
										$("#dialog_survey_request .panels .panel:eq(2)").addClass("os-dialog__panel--active");
										$(this).html(i18next.t('survey_request:button.send'));
										$('#dialog_survey_request button[data-action="survey-request-next-step"]').data("step", 3);
									} else {
										$(this).find('span').remove();
										$('#dialog_survey_request form[name="survey_request_form"] input[name="siren"]').parents('.form-group').addClass('has-error');
										$('#dialog_survey_request form[name="survey_request_form"] input[name="siren"]').parents('.form-group').find('.help-block').show();
									}
								});
							}
						} else {
							$("#dialog_survey_request .panels .panel").removeClass("os-dialog__panel--active");
							$("#dialog_survey_request .panels .panel:eq(2)").addClass("os-dialog__panel--active");
							$(this).html(i18next.t('survey_request:button.send'));
							$('#dialog_survey_request button[data-action="survey-request-next-step"]').data("step", 3);
						}
						break;
					case 3:
						$('#dialog_survey_request form[name="survey_request_form"]').parsley().whenValidate({
							group: 'step2',
							force: true
						}).then(function(){
							//Validate form
							$.ajax({
								url: '/api/survey/request',
								type: 'POST',
								data: formData($('form[name="survey_request_form"] input[name="email"]')),
								dataType: 'json',
								context: this
							}).done(function (data) {
								$(this).hide();
								$(this).parents(".mdc-dialog").addClass("mdc-dialog-alert");
								if (data.success) {
									tabIndex = 3;
									$('#dialog_survey_request button[data-action="survey-request-next-step"]').hide();
								} else {
									if (data.found_survey_request == 1) {
										$('#dialog_survey_request .panels .panel:eq(4) #duplicate_request_error').show();
									} else if (data.found_survey == 1 || data.found_survey_answered == 1) {
										$('#dialog_survey_request .panels .panel:eq(4) #duplicate_survey_error').show();
									} else {
										$('#dialog_survey_request .panels .panel:eq(4) #general_error').show();
									}
									tabIndex = 4;
								}
								$("#dialog_survey_request .panels .panel").removeClass("os-dialog__panel--active");
								$("#dialog_survey_request .panels .panel:eq(" + tabIndex + ")").addClass("os-dialog__panel--active");

							});
						});
						break;
				}
			});
		}
	};
});