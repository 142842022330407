var Handlebars = require("/www/www.mmartel.opinionsystem.successmarket.fr/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<aside id=\"dialog_login\" class=\"mdc-dialog os-dialog static\" role=\"alertdialog\" aria-labelledby=\"my-mdc-dialog-label\"\n	aria-describedby=\"dialog_login_description\" data-dialog-static=\"true\">\n	<div class=\"mdc-dialog__surface os-dialog__surface--small\">\n		<header class=\"mdc-dialog__header\">\n			<h2 id=\"mdc-dialog-with-list-label\" class=\"mdc-dialog__header__title\">\n				"
    + ((stack1 = __default(require("/www/www.mmartel.opinionsystem.successmarket.fr/module/handlebars-helpers/i18n_t.js")).call(alias1,"login:title",{"name":"i18n_t","hash":{},"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":6,"column":30}}})) != null ? stack1 : "")
    + "\n			</h2>\n		</header>\n		<form action=\"https://"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.login_url : depth0), depth0))
    + "\" class=\"form-horizontal\" method=\"post\" modal=\"true\">\n			<section id=\"dialog_login_description\" class=\"mdc-dialog__body\">\n				<div class=\"dialog-body-title\">"
    + ((stack1 = __default(require("/www/www.mmartel.opinionsystem.successmarket.fr/module/handlebars-helpers/i18n_t.js")).call(alias1,"login:body_title",{"name":"i18n_t","hash":{},"data":data,"loc":{"start":{"line":11,"column":35},"end":{"line":11,"column":66}}})) != null ? stack1 : "")
    + ":</div>\n				<div class=\"mdc-layout-grid no-padding\">\n					<div class=\"mdc-layout-grid__inner\">\n						<div\n							class=\"mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop\">\n							<div class=\"form-group\">\n								<div class=\"mdc-text-field os-text-field mdc-text-field--fullwidth mdc-text-field--box\">\n									<input type=\"text\" id=\"login_user_name\" name=\"user_name\"\n										class=\"mdc-text-field__input\">\n									<label for=\"login_user_name\"\n										class=\"mdc-floating-label os-floating-label mdc-floating-label--shake\">"
    + ((stack1 = __default(require("/www/www.mmartel.opinionsystem.successmarket.fr/module/handlebars-helpers/i18n_t.js")).call(alias1,"login:username",{"name":"i18n_t","hash":{},"data":data,"loc":{"start":{"line":21,"column":81},"end":{"line":21,"column":110}}})) != null ? stack1 : "")
    + "\n										*</label>\n									<div class=\"mdc-line-ripple\"></div>\n								</div>\n							</div>\n						</div>\n					</div>\n				</div>\n				<div class=\"mdc-layout-grid no-padding\">\n					<div class=\"mdc-layout-grid__inner\">\n						<div\n							class=\"mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop\">\n							<div class=\"form-group\">\n								<div class=\"mdc-text-field os-text-field mdc-text-field--fullwidth mdc-text-field--box\">\n									<input type=\"password\" id=\"login_password\" name=\"password\"\n										class=\"mdc-text-field__input\">\n									<label for=\"login_password\"\n										class=\"mdc-floating-label os-floating-label\">"
    + ((stack1 = __default(require("/www/www.mmartel.opinionsystem.successmarket.fr/module/handlebars-helpers/i18n_t.js")).call(alias1,"login:password",{"name":"i18n_t","hash":{},"data":data,"loc":{"start":{"line":38,"column":55},"end":{"line":38,"column":84}}})) != null ? stack1 : "")
    + "\n										*</label>\n									<div class=\"mdc-line-ripple\"></div>\n								</div>\n							</div>\n						</div>\n					</div>\n				</div>\n				<a href=\"#\" class=\"modal-forgot-password\"\n					data-mdc-dialog-action=\"close\">"
    + ((stack1 = __default(require("/www/www.mmartel.opinionsystem.successmarket.fr/module/handlebars-helpers/i18n_t.js")).call(alias1,"login:forgot_password",{"name":"i18n_t","hash":{},"data":data,"loc":{"start":{"line":47,"column":36},"end":{"line":47,"column":72}}})) != null ? stack1 : "")
    + "</a>\n			</section>\n			<footer class=\"mdc-dialog__footer\">\n				<button type=\"button\"\n					class=\"mdc-button mdc-dialog__footer__button mdc-dialog__footer__button--cancel mdc-button--outlined os-button--primary-outlined\">"
    + ((stack1 = __default(require("/www/www.mmartel.opinionsystem.successmarket.fr/module/handlebars-helpers/i18n_t.js")).call(alias1,"login:button.close",{"name":"i18n_t","hash":{},"data":data,"loc":{"start":{"line":51,"column":135},"end":{"line":51,"column":168}}})) != null ? stack1 : "")
    + "</button>\n				<button type=\"submit\"\n					class=\"mdc-button mdc-dialog__footer__button mdc-button--raised os-button--primary\"\n					data-action=\"login\" data-step=\"1\">"
    + ((stack1 = __default(require("/www/www.mmartel.opinionsystem.successmarket.fr/module/handlebars-helpers/i18n_t.js")).call(alias1,"login:button.connect",{"name":"i18n_t","hash":{},"data":data,"loc":{"start":{"line":54,"column":39},"end":{"line":54,"column":74}}})) != null ? stack1 : "")
    + "</button>\n			</footer>\n		</form>\n	</div>\n	<div class=\"mdc-dialog__backdrop\"></div>\n</aside>";
},"useData":true});