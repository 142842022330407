
define(['@babel/polyfill', 'i18next', 'js-cookie'], function(babelPolyfill, i18next, jsCookie) {


	var locale=jsCookie.get('locale');

	return new Promise(function(resolve) {
		
		import('../../locale/'+locale+'/index.js').then(function(resources){
			
			i18next.init(
			{
				lng: locale,
				initImmediate: false,
				fallbackLng: false,
				resources: resources.default,
				load: 'currentOnly'
			});

			resolve(true);

		});
	});

});
