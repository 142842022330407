require('@material/form-field/mdc-form-field.scss');
require('flag-icon-css/sass/flag-icon.scss');

require('@os/mdc/text-field.scss');
require('@os/mdc/floating-label.scss');

define(['jquery', '@material/textfield', '@material/menu', 'i18next', 'parsleyjs'], function ($, mdcTextField, mdcMenu, i18next, parsley) {

	return {
		init: function () {
			if (window.Parsley.asyncValidators.mycustom) {
				delete window.Parsley.asyncValidators.mycustom;
			}
			window.Parsley.addAsyncValidator('mycustom', function (xhr) {
				var data = JSON.parse(xhr.responseText);
				countryCode = data.country_code;
				countryCodeLowerCase = data.country_code.toLowerCase();
				if (this.$element.val()) {
					this.$element.parents(".widget-phone-mdc").find('button .country-regional-code').html(((data.phone_code) ? '+' + data.phone_code : ''));
					this.$element.parents(".widget-phone-mdc").find('button .country-icon').html('<span style="width: 18px; height: 12px; top: -3px;" class="flag-icon flag-icon-' + countryCodeLowerCase + '"></span>');

					this.$element.parents(".widget-phone-mdc").find('#phone_tooltip').html(i18next.t('phone-widget:popover_text', {
						country_name: data.country_name
					}));
					return data.valid;
				} else {
					this.$element.parents(".widget-phone-mdc").find('button .country-regional-code').html(((data.phone_code) ? '+' + data.phone_code : ''));
					this.$element.parents(".widget-phone-mdc").find('button .country-icon').html('<span style="width: 18px; height: 12px; top: -3px;" class="flag-icon flag-icon-' + countryCodeLowerCase + '"></span>');

					this.$element.parents(".widget-phone-mdc").find('#phone_tooltip').html(i18next.t('phone-widget:popover_text', {
						country_name: data.country_name
					}));
					return true;
				}
			}, '/api/phone/widget');

			$(document).find('.widget-phone-wrapper').each(function(index, element){
				var number = $(element).find(".phone-widget-input input").val();
				var country_code = $(element).find(".country-code").val();
				var country_code_lower_case = country_code.toLowerCase();
				var language = $(element).find(".phone_widget_language").val();

				$(element).find(".mdc-button__icon.country-icon").html('<span style="width: 18px; height: 12px; top: -3px;" class="flag-icon flag-icon-' + country_code_lower_case + '"></span>');

				if (number) {
					$.ajax({
						url: '/api/phone/widget',
						type: 'POST',
						data: {
							method: 'validate_number',
							mobile: $(element).find(".phone-widget-input input").val(),
							country_code: $(element).find(".country-code").val()
						},
						dataType: 'json',
						success: function (data) {
							data.country_code_lower_case = data.country_code.toLowerCase();

							$(element).find(".mdc-button__icon.country-icon").html('<span style="width: 18px; height: 12px; top: -3px;" class="flag-icon flag-icon-' + data.country_code_lower_case + '"></span>');
	
							data.countryList = [];
							$.each(data.country_list, function (countryCode, countryName) {
								data.countryList.push({
									'country_code': countryCode,
									'country_code_lower_case': countryCode.toLowerCase(),
									'country_name': countryName,
									'flag_position': (((ord(countryCode.substr(0, 1).toLowerCase()) - ord("a")) * 26) + (ord(countryCode.substr(1, 1).toLowerCase())) - ord("a")) * 11
								});
							});
							$(element).find('ul[role="menu"].mdc-list').empty();
							$.each(data.countryList, function (key, value) {
								$(element).find('ul[role="menu"].mdc-list').append('<li class="mdc-list-item" role="menuitem" tabindex="0" code="' + value.country_code + '"><div class="country-icon"><span  style="width: 18px; height: 12px; top: -7px;" class="flag-icon flag-icon-' + value.country_code_lower_case + '"></span></div>' + value.country_name + '</li>');
							});
	

							$(element).find(".phone-widget-input input").attr("data-parsley-remote-options", '{"type":"POST", "dataType":"json","data":{"method": "validate_number","country_code":"' + $(element).find('.country-code').val() + '","number":"' + $(element).find(".phone-widget-input input").val() + '"}}');

							if (data.phone_code) {
								$(element).find(".country-regional-code").html("+" + data.phone_code);
							} else {
								$(element).find(".country-regional-code").html("+33");
							}
							$(element).find('#phone_tooltip').html(i18next.t('phone-widget:popover_text', {
								country_name: data.country_name
							}));

							$(element).find('.mdc-list li').on('click', function(event){
								$(element).find('.phone-widget-input input').parsley().reset();
								$(element).find('.country-code').val($(this).attr("code"));
								$(element).find(".phone-widget-input input").attr("data-parsley-remote-options", '{"type":"POST", "dataType":"json","data":{"method": "validate_number","country_code":"' + $(element).find('.country-code').val() + '","number":"' + $(element).find(".phone-widget-input input").val() + '"}}');
								$(element).find('.phone-widget-input input').trigger('change');
								event.preventDefault();
							});
						}
					});
				} else {
					$.ajax({
						url: '/api/phone/widget',
						type: 'POST',
						data: {
							method: 'country_list',
							lang: language
						},
						dataType: 'json',
						success: function (data) {
							data.countryList = [];
							$.each(data.country_list, function (countryCode, countryName) {
								data.countryList.push({
									'country_code': countryCode,
									'country_code_lower_case': countryCode.toLowerCase(),
									'country_name': countryName,
									'flag_position': (((ord(countryCode.substr(0, 1).toLowerCase()) - ord("a")) * 26) + (ord(countryCode.substr(1, 1).toLowerCase())) - ord("a")) * 11
								});
							});
							$(element).find('ul[role="menu"].mdc-list').empty();
							$.each(data.countryList, function (key, value) {
								$(element).find('ul[role="menu"].mdc-list').append('<li class="mdc-list-item" role="menuitem" tabindex="0" code="' + value.country_code + '"><div class="country-icon"><span  style="width: 18px; height: 12px; top: -7px;" class="flag-icon flag-icon-' + value.country_code_lower_case + '"></span></div>' + value.country_name + '</li>');	
							});
	

							$(element).find(".phone-widget-input input").attr("data-parsley-remote-options", '{"type":"POST", "dataType":"json","data":{"method": "validate_number","country_code":"' + $(element).find('.country-code').val() + '"}}');
							$(element).find('#phone_tooltip').html(i18next.t('phone-widget:popover_text', {
								country_name: data.country_name
							}));

							$(element).find(".country-regional-code").html("+33");
							$(element).find('.widget-phone-mdc li[code="FR"]').click();
							$(element).find(".phone-widget-input input").parsley().reset();

							$(element).find('.mdc-list li').on('click', function(event){
								$(element).find('.phone-widget-input input').parsley().reset();
								$(element).find('.country-code').val($(this).attr("code"));

								$(element).find(".phone-widget-input input").attr("data-parsley-remote-options", '{"type":"POST", "dataType":"json","data":{"method": "validate_number","country_code":"' + $(element).find('.country-code').val() + '","number":"' + $(element).find(".phone-widget-input input").val() + '"}}');

								$(element).find('button .country-icon').html('<span style="width: 18px; height: 12px; top: -3px;" class="flag-icon flag-icon-' + $(this).attr("code").toLowerCase() + '"></span>');

								$(element).find('.phone-widget-input input').trigger('change');
								event.preventDefault();
							});
						}
					});
				} 
			
				mdcTextField.MDCTextField.attachTo($(element).find('.mdc-text-field.phone-widget-input')[0]);
				
				$(element).find('.country-icon-button').click(function(button){
					button.preventDefault();
					button.stopPropagation();
					var menu = new mdcMenu.MDCMenu($(this).parent().find('.mdc-menu')[0]);
					menu.open = !menu.open;
				});

				$(element).find('.mdc-text-field__icon').on('mouseover', function(){
					$(element).find('.widget-phone-mdc-tooltip').css('right', ($(element).find('.widget-phone-mdc-tooltip').width() * -1) + 'px').show();
				});

				$(element).find('.mdc-text-field__icon').on('mouseout', function(){
					$(element).find('.widget-phone-mdc-tooltip').hide();
				});
				$(element).find('.phone-widget-input').on('click', function () {
					$(element).find('.widget-phone-mdc-tooltip').css('right', ($(element).find('.widget-phone-mdc-tooltip').width() * -1) + 'px');
					$(element).find('.widget-phone-mdc-tooltip').toggle();
				});				
				
			});
		}
	};

	function ord(string) {
		var str = string + '',
			code = str.charCodeAt(0);

		if (0xD800 <= code && code <= 0xDBFF) { // High surrogate (could change last hex to 0xDB7F to treat high private surrogates as single characters)
			var hi = code;
			if (str.length === 1) {
				return code; // This is just a high surrogate with no following low surrogate, so we return its value;
				// we could also throw an error as it is not a complete character, but someone may want to know
			}
			var low = str.charCodeAt(1);
			return ((hi - 0xD800) * 0x400) + (low - 0xDC00) + 0x10000;
		}

		if (0xDC00 <= code && code <= 0xDFFF) { // Low surrogate
			return code; // This is just a low surrogate with no preceding high surrogate, so we return its value;
			// we could also throw an error as it is not a complete character, but someone may want to know
		}
		return code;
	}

});