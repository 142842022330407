require('@material/dialog/mdc-dialog.scss');
require('@material/form-field/mdc-form-field.scss');
require('@material/checkbox/mdc-checkbox.scss');

require('@os/mdc/dialog.scss');
require('@os/mdc/text-field.scss');
require('@os/mdc/checkbox.scss');
require('@os/mdc/floating-label.scss');

require('../../sass/enroll/enroll.scss');

define(['jquery', '@material/dialog', '@material/textfield',
	'@material/checkbox', '@material/radio', '@os/form-data',
	'parsleyjs', '@os/google-recaptcha', 'js-cookie'
], function ($, mdcDialog, mdcTextField, mdcCheckbox,
	mdcRadio, formData, parsley, googleRecaptcha, jsCookie) {

	var templateEnroll = require('../../view/enroll.hbs');

	return {
		init: function () {
			$(document).on('click', '[data-action="enroll"]', function (event) {
				event.preventDefault();
				event.stopPropagation();

				var templateData = {
					locale: jsCookie.get('locale').toLowerCase()
				};

				if ($('#modalEnroll').length > 0) {
					$('#modalEnroll').remove();
				}
				$("body").append(templateEnroll(templateData));
				$('#modalEnroll #enrollForm').find('.mdc-text-field').each(function (index, value) {
					mdcTextField.MDCTextField.attachTo($(this)[0]);
				});

				$('#modalEnroll #enrollForm').find('.mdc-checkbox').each(function (index, value) {
					checkbox = mdcCheckbox.MDCCheckbox.attachTo($(this)[0]);
				});

				$('#modalEnroll #enrollForm').find('.mdc-radio').each(function (index, value) {
					radio = mdcRadio.MDCRadio.attachTo($(this)[0]);
				});

				dialog = new mdcDialog.MDCDialog(document.querySelector('#modalEnroll'));
				$('#modalEnroll form[name="enroll_form"]').parsley({
					excluded: 'input[type=button],input[type=submit],input[type=reset],[disabled]'
				});
				googleRecaptcha.render('#modalEnroll form[name="enroll_form"]');
				dialog.show();
			});

			$(document).on('click', 'button[data-action="enroll-next-step"]', function (event) {
				event.preventDefault();
				event.stopPropagation();

				switch ($(this).data("step")) {
					case 1:
						if ($('#modalEnroll form[name="enroll_form"]').parsley().validate({
							group: 'step1',
							force: true
						})) {
							$.ajax({
								url: '/api/enroll',
								type: 'POST',
								data: formData($('#enrollForm input[name="company_name"]')),
								dataType: 'json',
								context: this
							}).done(function (data) {
								$(this).hide();
								$(this).parents(".mdc-dialog").addClass("mdc-dialog-alert");
								if (data.success) {
									if (data.brochure) {
										$('#brochure_text').show();
									}
									tabIndex = 1;
								} else {
									tabIndex = 2;
								}
								$("#enrollForm .panels .panel").removeClass("os-dialog__panel--active");
								$("#enrollForm .panels .panel:eq(" + tabIndex + ")").addClass("os-dialog__panel--active");
							});
						}
						break;
				}
			});

			if (window.location.hash) {
				var hash = window.location.hash.substring(1);
				if (hash == "demo")
					$(".invoke-demo").click();
			}
		}
	};
});
